import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  usuario: any;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal, private _authService: AuthenticationService, private router: Router, ) {
    let userVal = this._authService.currentUserValue;
    this.usuario = userVal.user;
    console.log(this.usuario)
  }

  public showSearch = false;

  ngAfterViewInit() {

   }
  cerrarSession() {
    this._authService.logout();
    this.router.navigate(['/login'])
  }
}
