import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, RequiredValidator } from '@angular/forms';
import { ClienteService, DataService, AuthenticationService, ValidationService } from '../../../services/service.index';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-edicion-cliente',
  templateUrl: './edicion-cliente.component.html',
  styleUrls: ['./edicion-cliente.component.css']
})
export class EdicionClienteComponent implements OnInit {
  frmCliente: any;
  usosCfdi: [];
  usuario: any;
  arrRegimen:[];
  constructor(
    private formBuilder: FormBuilder,
    private _activateRouter: ActivatedRoute,
    private _route: Router,
    private _clienteService: ClienteService,
    private _dataService: DataService,
    private _authService: AuthenticationService) { }

  ngOnInit() {
    this.usuario = this._authService.getUser();
    this._dataService.getRegimenFiscal().subscribe(res=>this.arrRegimen=res);
    this.frmCliente = this.crearFormulario();
    this.getUsosCfdi();
    this._activateRouter.params.subscribe(param => {
      if (param['id'] != 'nuevo') {
        //Obtener el cliente
        Swal.showLoading();
        this._clienteService.obtenerPorId(param['id']).subscribe((data: any) => {
          
          if(data.usoCfdi=='' || data.usoCfdi==null){
           data.usoCfdi="G03";
          }
          this.frmCliente.patchValue(data);
          Swal.hideLoading();
          Swal.close();
        })
      }
    });
  }
  cambiarUsoCfdi(data) {

    this.usosCfdi.forEach(function (item: any) {
      if (item.codigoUsoCfdi === data) {

      }
    });
  }
  crearFormulario() {
    return this.formBuilder.group({
      razonSocial: [],
      rfc: ['', ValidationService.rfcValidator],
      calle: [''],
      numInterior: [null],
      numExterior: [null],
      colonia: [null],
      codigoPostal: [null,ValidationService.cpostalValidator],
      localidad: [null],
      municipio: [null],
      telefono: [null, ValidationService.numberValidator],
      numCuenta: [null],
      estado: [null],
      pais: [null],
      correo: [null],
      usoCfdi: [3],
      regimenFiscal:['',ValidationService.validRegimen],
      empresaId: [this.usuario.empresaId],
      id: [null]
    });
  }
  getUsosCfdi() {
    this._dataService.getUsoCfdi().subscribe(data => {
      this.usosCfdi = data;
    })
  }
  onSubmist() {
    /*this.usosCfdi.forEach(function (item: any) {
      if (item.id === this.frmCliente.value.usoCfdi) {
        this.frmCliente.value.usoCfdi = item.codigoUsoCfdi;
      }
    });*/
    //console.log(this.frmCliente.value);
  }
  onSubmit() {
    if(this.frmCliente.value.regimenFiscal==null || this.frmCliente.value.regimenFiscal==''){
      Swal.fire({
        position: 'top-end',
        type: 'error',
        title: 'Regimen Fiscal obligatorio',
        showConfirmButton: true,
        timer: 1500
      });
      return;
    }

    if(this.frmCliente.value.usoCfdi==''||this.frmCliente.value.usoCfdi==''){
      this.frmCliente.value.usoCfdi='G03';
    }

    Swal.fire({
      title: 'Procesando ...',
      showLoaderOnConfirm: true,
      onOpen: (data) => {
        // console.log('Al abrir modal...');
        Swal.showLoading();
        this._clienteService.almacenar(this.frmCliente.value)
          .subscribe((resp: any) => {
            if (!resp.response) {
              let msj = '';
              if (resp.errors) {
                for (let item of resp.errors) {
                  msj += `<br>${item}`;
                }
              }
              Swal.showValidationMessage(
                `<div class="alert alert-danger" role="alert">Error en el proceso: ${msj}</div>`
              );
            }
            Swal.hideLoading();
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Cliente registrado',
              showConfirmButton: false,
              timer: 1500
            });
            this.reset();
            return resp;
          },
            (err: any) => {
              Swal.hideLoading();
              Swal.close();
              Swal.showValidationMessage(
                `Error en el proceso: ${err}`
              )
            });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }
  reset() {
    this.frmCliente.reset();
    this.frmCliente = this.crearFormulario();
    //this.router.navigate(['/productos']);
  }
}
