import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, ClienteService, ProductoService, Invoce, FacturaService, AuthenticationService,EmpresaService } from '../../../services/service.index';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { ValidationService } from '@app/services/shared/validation.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-generar-factura',
  templateUrl: './generar-factura.component.html',
  styleUrls: ['./generar-factura.component.css']
})
export class GenerarFacturaComponent implements OnInit {
  @ViewChild('content2') content2;
  Factura: any;
  data = [];
  productos = [];
  productoSel: any;
  notFound = "No encontrado.";
  keyword = "razonSocial";
  keywordProducto = "descripcion";
  cliente: any = {};
  usosCfdi: [];
  formasDePago: [];
  detalle: [];
  Invoce: Invoce = new Invoce();
  txtCuenta = false;
  usuario: any;
  showpdf = false;
  srcpdf: any;
  calculoInverso = false;
  ci = { total: 0, base: 0, iva: 0 };
  editarItem = false;
  showSearch: false;
  indexSel = null;
  sucursal=null;

  constructor(
    private formBuilder: FormBuilder,
    private _clienteService: ClienteService,
    private _dataService: DataService,
    private _productoService: ProductoService,
    private _facturaService: FacturaService,
    private _authService: AuthenticationService,
    private _empService:EmpresaService,
    private _modalService: NgbModal,
    public router: Router,
    public activatedRoute: ActivatedRoute) {

   

   
    this.usuario = this._authService.getUser();
    this._empService.getSucursal(this.usuario.sucursalId).subscribe((res:any)=>{
      this.sucursal=res;
      this.Invoce.porcentajeRetencion=parseFloat(res.retencioniva)/100;
      //console.log(this.Invoce)
    })

    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      if (params['id'] !== 'new') {
        Swal.showLoading();
        this._facturaService.obtener(id).subscribe((data: any) => {
          this.obtenerCliente(data.idCliente);
          Swal.hideLoading();
          Swal.close();
        });
        this._facturaService.obtenerDetalle(id).subscribe((data: any) => {
          this.procesarProducto(data);
        });
      }
    });

    this.Factura = this.createFormGroup();
    this.Invoce.empresaId = this.usuario.empresaId;
    this.Invoce.sucursalId = this.usuario.sucursalId;
    this.Invoce.cliente = false;
    this.getFormasPago();
    this.getUsosCfdi();
    
  }

  ngOnInit() {

  }
  procesarProducto(dada: any) {
    for (let producto of dada) {
      this.productoSel = {
        claveProdServ: producto.claveprodserv,
        claveUnidad: producto.claveUnidad,
        id: producto.id,
        unidad: producto.unidadMedida,
        cantidad: producto.cantidad,
        descripcion: producto.descripcion,
        precioUnitario: this.Invoce.round(producto.precioUnitario, 6),
        importe: 0,
        iva: 0,
        aplicaIva: producto.aplicaIva,
        total: 0,
        tipoFactor:producto.tipoFactor,
        tasaOCuota: producto.tasaOCuota
      };
      this.calcular();
      this.Invoce.detalle.push(this.productoSel);
    }
    this.Invoce.calculoGlobal();
    this.productoSel = false;
  }
  createFormGroup() {
    return this.formBuilder.group(
      {
        cliente: this.formBuilder.group(
          {
            id: [''],
            razonSocial: [''],
            rfc: [''],
            correo: [''],
            municipio: [''],
            estado: [''],
            calle: [''],
            numeroInterior: [''],
            localidad: [''],
            numCuenta: [''],
            regimenFiscal:['']
          }),
        invoce: this.formBuilder.group(
          {
            formaPago: ['1'],
            detalle: [],
            metodoPago: ['PUE'],
            usoCfdi: ['G03'],
          })
      }
    );
  }
  onSubmit() {
    this.Invoce.cliente = this.Factura.value.cliente;
    if (this.Invoce.detalle.length > 0) {
      Swal.fire({
        title: 'Procesando ...',
        showLoaderOnConfirm: true,
        onOpen: (data) => {
          Swal.showLoading();
          this._facturaService.post(this.Invoce)
            .subscribe((resp: any) => {
              Swal.hideLoading();
              Swal.close();
              Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Factura Generada',
                showConfirmButton: false,
                timer: 1000
              });
              this.imprimir(resp.uuid);
            },
              (err: any) => {
                Swal.hideLoading();
                Swal.showValidationMessage(
                  `Error en el proceso: ${err}`
                )
              });

        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    } else {
      Swal.fire({
        position: 'top-end',
        type: 'error',
        title: 'Ingrese un producto a facturar.',
        showConfirmButton: false,
        timer: 2000
      });

      Swal.showValidationMessage(
        `Ingrese un concepto a facturar.`
      )
    }

  }
  buscarCliente($evento) {
    this._clienteService.search($evento).subscribe((data: any) => {
      this.data = data.items;
    });
  }
  getFormasPago() {
    this._dataService.getFormasPago().subscribe(data => {
      this.formasDePago = data;
      this.Factura.patchValue({FormaPago:'01'})
    })
  }
  getUsosCfdi() {
    this._dataService.getUsoCfdi().subscribe(data => {
      this.usosCfdi = data;
    })
  }
  obtenerCliente(id: any) {
    this._clienteService.obtenerPorId(id).subscribe((data: any) => {
      this.cliente = data;
      //Cliente no actualizado
      if(data.v4=='N'){
        this.router.navigate(['cliente/edicion/' + data.id])
      }
      this.Invoce.cliente = data;
      this.Factura.patchValue({ cliente: data });
    })
  }
  onSelectCliente($evento) {
    this.obtenerCliente($evento);
  }
  buscarProducto($event) {
    this._productoService.search($event).subscribe((data: any) => {
      this.productos = data.items;
    });
  }

  onSelectProducto(producto: any) {
    this.productoSel = this.crearProducto(producto, 1);
    this.calcular();
  }
  crearProducto(producto, cantidad) {
    var nuevo = {
      claveProdServ: producto.claveprodserv,
      claveUnidad: producto.claveUnidad,
      id: producto.id,
      unidad: producto.unidadMedida,
      cantidad: cantidad,
      descripcion: producto.descripcion,
      precioUnitario: producto.precio,
      importe: 0,
      iva: 0,
      aplicaIva: producto.aplicaIva,
      tipoFactor:producto.tipoFactor,
      total: 0,
      tasaOCuota: producto.tasaOCuota
    };
    return nuevo;
  }
  agregarProducto() {
    this.Invoce.detalle.push(this.productoSel);
    this.Invoce.calculoGlobal();
    this.productoSel = false;
  }

  calcular() {
    
    if (this.productoSel.tipoFactor=="Tasa") {
      let calcImporte = parseFloat(this.productoSel.precioUnitario) * parseFloat(this.productoSel.cantidad);
      this.productoSel.importe = this.Invoce.round(calcImporte, 6);
      //Calcular el iva por prdducto e incrementar al iva global.
      var calcIva = this.productoSel.importe * this.productoSel.tasaOCuota;
      this.productoSel.iva = this.Invoce.round(calcIva, 6);
      let total = this.productoSel.iva + this.productoSel.importe;
      this.productoSel.total = this.Invoce.round(total, 6);
    } else {
      let calcImporte = parseFloat(this.productoSel.precioUnitario) * parseFloat(this.productoSel.cantidad);
      this.productoSel.importe = this.Invoce.round(calcImporte, 6);
      this.productoSel.total = this.productoSel.importe;
    }
  }
  validarFormaPago() {
    let tipo = parseInt(this.Invoce.formaPago);
    if (tipo === 1 || tipo === 5) this.txtCuenta = false;
    else {
      this.txtCuenta = true;
    }
  }

  imprimir(id: any) {
    this._facturaService.imprimir(id).subscribe((data: any) => {
      this.srcpdf = data.pdf;
      this.showpdf = true;
      this._modalService.open(this.content2, {
        size: "lg",
        centered: true,
        backdrop: false,
        keyboard: false,
        //closeOnOutsideClick: false,
        //backdropClass: "modal-backdrop"
      }).result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    })
    this.Invoce = new Invoce();
  }
  calcularInverso() {
    //Hacer calculo inverso.
    console.log('Entrada === ', this.ci);
    if (this.productoSel.tipoFactor="Tasa") {
      let subt = 1 + parseFloat(this.productoSel.tasaOCuota);
      this.ci.base = this.Invoce.round(this.ci.total / subt, 6);
      this.ci.iva = this.Invoce.round(this.ci.base * parseFloat(this.productoSel.tasaOCuota), 6);
    } else {
      this.ci.base = this.Invoce.round(this.ci.total, 6);
      this.ci.iva = this.Invoce.round(0, 6);
    }
    console.log('SAlida === ', this.ci);
  }
  aplicarCalculoInverso() {
    this.productoSel.iva = this.ci.iva;
    this.productoSel.precioUnitario = this.ci.base;
    this.productoSel.importe = this.ci.base;
    this.productoSel.total = this.ci.total;
    this.productoSel.cantidad = 1;
    this.showSearch = false;
  }
  editarRegistro(index, item) {
    this.indexSel = index;
    let tmp = JSON.stringify(item);
    this.editarItem = true;
    this.productoSel = JSON.parse(tmp);
  }
  guardarCambios() {
    this.editarItem = false;
    this.Invoce.detalle[this.indexSel] = this.productoSel;
    this.Invoce.calculoGlobal();
    this.productoSel = false;
  }
}
